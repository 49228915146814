export const environment = {
    production: true,
    apiUrl: 'https://uat-api.shinematrimony.com/api',
    imagePath: 'https://uat-api.shinematrimony.com/api/Image/Get',
    appName: 'Shine Matrimony Admin Portal',
    razorPayClientId: 'rzp_live_nicGFgXBa8I1P0',
    uploadFileSize: '300000',
    rgxOnlyNumeric: '^[0-9]*$',
    rgxTenDigits: '/^[1-9]\d{9}$/',
    rgxDecimalRound2: '^[0-9]+(\.[0-9]{1,2})?$',
    gridItemsPerView: [5,10,25,50,100,500],
    gridDefaultItemsPerView: 10,
    SID: 'SM_ADMIN_'
};
