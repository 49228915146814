import { createAction, props } from "@ngrx/store";
import { UserInfo, OrderInfo, RegAccountInfo, RegUser, TokenHolder, UserCredentialModel, AuthUserPageFilterState } from "../../interfaces";

export const loginRequest = createAction(
    '[Auth] Login Request',
    props<{ loginModel: UserCredentialModel }>()
)

export const loginSuccess = createAction(
    '[Auth] Login Success',
    props<({ loginSuccessResponse: TokenHolder, email: string, password: string })>()
)

export const checkAccessRequest = createAction(
    '[Auth] Check Access Request'
)

export const checkAccessSuccess = createAction(
    '[Auth] Check Access Success',
    props<{ role: string }>()
)

export const fetchLoggedUser = createAction(
    '[Auth] Fetch Logged User'
)

export const fetchLatestProfileDetails = createAction(
    '[Auth] Fetch Profile Update Details'
)

export const updateLoggedUserState = createAction(
    '[Auth] Update Logged User State',
    props<{ user: RegUser }>()
)

export const saveLoggedUserState = createAction(
    '[Auth] Save Logged User State',
    props<{ user: RegUser }>()
)

export const loginFailure = createAction(
    '[Auth] Login Failure',
    props<{ error: any }>()
)

export const updateAccessToken = createAction(
    '[Auth] Update Access Token',
    props<{ accessToken: string, refreshToken: string }>()
)

export const fetchAccountInfo = createAction(
    '[Auth] Fetch Account Info',
    props<{ userSNo: number }>()
)

export const saveAccountInfo = createAction(
    '[Auth] Save Account Info',
    props<{ accountInfo: RegAccountInfo }>()
)

//#region OTP Login start
export const otpLoginRequest = createAction(
    '[Auth] OTP Login Request',
    props<{ mobileNumber: number, otp: string }>()
)

export const otpLoginSuccess = createAction(
    '[Auth] Login Success',
    props<({ loginSuccessResponse: TokenHolder })>()
)
//#endregion OTP Login end

export const saveOrderInfoAndUserInfo = createAction(
    '[Auth] Save OrderInfo And UserInfo',
    props<({ orderInfo: OrderInfo, userInfo: UserInfo })>()
)

// Users Page Actions Start

export const fetchUserPageData = createAction(
    '[UserPage] Fetch User Page Data',
    props<({ filters: AuthUserPageFilterState })>()
)

export const saveUserPageData = createAction(
    '[UserPage] Save User Page Data',
    props<({ users: RegUser[] })>()
)


// Users Page Actions End
export const logOut = createAction(
    '[Auth] Log Out'
)
