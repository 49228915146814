import {
    Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, Output,
    SimpleChanges, ChangeDetectorRef, ChangeDetectionStrategy, NgZone, ViewChild, OnInit, AfterViewInit, 
    forwardRef, Inject
} from '@angular/core';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { MoveStart, Dimensions, CropperPosition, ImageCroppedEvent } from '../interfaces';
import { resetExifOrientation, transformBase64BasedOnExifRotation } from '../utils/exif.utils';
import { resizeCanvas, fitImageToAspectRatio } from '../utils/resize.utils';
import { ImgCropperEvent, ImgCropperEventTarget } from '../../../../interfaces';
import { AlertMessageService } from '../../../../services/alert-message.service';
import { CommonOperations } from '../../../../helpers/common-operations';
import { FileUploadService } from '../../../../services/file-upload.service';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import {
    NG_VALUE_ACCESSOR,
    ControlValueAccessor
} from "@angular/forms";
import { noop } from "rxjs";
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../../environments/environment';

export type OutputType = 'base64' | 'file' | 'both';

interface AspectRatio {
    SNo: number;
    Name: string;
    Value: number;
}
@Component({
    selector: 'image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ 
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageCropperComponent),
            multi: true
        }
    ]
})
export class ImageCropperComponent implements ControlValueAccessor, OnChanges, OnInit, AfterViewInit {
    private originalImage: any;
    private originalBase64: string = '';
    private moveStart: MoveStart = {} as MoveStart;
    private maxSize: Dimensions = {} as Dimensions;
    private originalSize: Dimensions = {} as Dimensions;
    private setImageMaxSizeRetries = 0;
    private cropperScaledMinWidth = 20;
    private cropperScaledMinHeight = 20;
    lstSelectedImages: ImageCroppedEvent[] = [] as ImageCroppedEvent[];
    cropItemIndex: number = 0;
    profilePicIndex: number = 0;
    event: ImgCropperEvent = {} as ImgCropperEvent;
    lstAspectRatio: AspectRatio[] = [];
    fileSize: number = 0;
    safeImgDataUrl: SafeUrl | string = '';
    marginLeft: SafeStyle | string = '0px';
    imageVisible = false;
    aspectRatio: number = 0;
    @ViewChild('sourceImage', { static: true }) sourceImage!: ElementRef;
    @ViewChild('fileInput') fileInput!: ElementRef;
    @Input()
    set imageChangedEvent(event: any) {
        this.initCropper();
        if (event && event.target && event.target.files && event.target.files.length > 0) {
            this.loadImageFile(event.target.files[0]);
        }
    }

    @Input()
    set imageBase64(imageBase64: string) {
        this.initCropper();
        this.checkExifAndLoadBase64Image(imageBase64);
    }

    @Input()
    set imageFile(file: File) {
        this.initCropper();
        if (file) {
            this.loadImageFile(file);
        }
    }
    @Input() id!: string;
    @Input() format: 'png' | 'jpeg' | 'bmp' | 'webp' | 'ico' = 'png';
    @Input() outputType: OutputType = 'base64';
    @Input() maintainAspectRatio = true;
    @Input() resizeToWidth = 0;
    @Input() resizeToHeight = 0;
    @Input() cropperMinWidth = 0;
    @Input() cropperMinHeight = 0;
    @Input() roundCropper = false;
    @Input() onlyScaleDown = false;
    @Input() imageQuality = 92;
    @Input() autoCrop = true;
    @Input() backgroundColor!: string;
    @Input() containWithinAspectRatio = false;
    @Input() cropper: CropperPosition = {
        x1: -100,
        y1: -100,
        x2: 10000,
        y2: 10000
    };
    @HostBinding('style.text-align')
    @Input() alignImage: 'left' | 'center' = 'center';
    @Input() multiple!: boolean;
    @Input() enableProfilePicSelection!: boolean;

    private onTouched: () => void = noop;
    private onChange: (_: any) => void = noop;

    @Output() imageCropped = new EventEmitter<ImageCroppedEvent>();
    @Output() startCropImage = new EventEmitter<void>();
    @Output() imageLoaded = new EventEmitter<void>();
    @Output() cropperReady = new EventEmitter<void>();
    @Output() loadImageFailed = new EventEmitter<void>();
    // @Output() selectedImages = new EventEmitter();
    // @Output() initialized: EventEmitter<ImageCropperComponent> = new EventEmitter<ImageCropperComponent>();
    // aspectRatio: number;

    ratioChanged(ratio: number) {
        if (ratio) {
            // this.aspectRatio = ratio;
            this.resetCropperPosition();
        }
    }
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private sanitizer: DomSanitizer,
        private cd: ChangeDetectorRef,
        private cOps: CommonOperations,
        private fileUploadService: FileUploadService,
        private zone: NgZone,
        private alertMessage: AlertMessageService,
        private confirmationService: ConfirmationService,
        private primengConfig: PrimeNGConfig
    ) {
        this.initCropper();
    }

    ngAfterViewInit(): void {
        this.fileSize = Number(environment.uploadFileSize);
        // this.initialized.emit(this);
    }
    ngOnInit() {
        this.lstSelectedImages = [] as ImageCroppedEvent[];
        this.lstAspectRatio = [] as AspectRatio[];
        this.lstAspectRatio = [
            { SNo: 1, Name: '1:1', Value: 1 },
            { SNo: 2, Name: '4:3', Value: 1.333 },
            { SNo: 3, Name: '16:9', Value: 1.777 },
            { SNo: 4, Name: '9:16', Value: 0.5625 },
        ];
        this.aspectRatio = 1;
    }

    writeValue(data: any) {
        if (data) {
            this.lstSelectedImages = data;
            this.onChange(data)
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setValueChange(value: any) {
        this.onChange(value);
    }

    async fileChangeEvent(event: any) {
        // this.imageChangedEvent = event;
        if (event.target.files.length === 0) {
            return;
        }
        const result = await this.getImgElements(event.target.files);
        const list = result.filter((c: any) => c !== false);
        if (!!list?.length) {
            this.lstSelectedImages = list;
            // this.selectedImages.emit(this.lstSelectedImages);
            this.onChange(this.lstSelectedImages);
        }
    }

    getImgElements(files: File[]): Promise<any> {
        let i = 1;
        return Promise.all(
            Array.from(files).map(async (file: File) => {
                const index = i++;
                // return await this.getImgElement(file, index);
                const promise = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    const mimeType = file.type;
                    if (mimeType.match(/image\/*/) == null) {
                        console.log('Only images are supported.');
                    } else {
                        reader.readAsDataURL(file);
                        reader.onload = (_event) => {
                            if (file.size <= this.fileSize) {
                                const image = {} as ImageCroppedEvent;
                                image.base64 = reader.result?.toString();
                                image.ContentType = file.type;
                                image.Name = file.name;
                                image.Order = index;
                                image.SNo = index;
                                image.Size = file.size;
                                resolve(image);
                            } else {
                                this.alertMessage.warningToastr('Image size max. ' + (this.fileSize / 1000) + 'kb');
                                resolve(false);
                            }
                        };
                    }
                });
                const response: any = await promise;
                return Promise.resolve(response === false ? false : response);
            })
        );
    }

    clearData() {
        this.fileInput.nativeElement.value = '';
        this.ngOnInit();
        this.initCropper();
    }

    onCloseCropper() {
        this.initCropper();
    }

    onCropSelectedImage(cropItem: any, cropItemIndex: number) {
        this.cropItemIndex = cropItemIndex;
        const byteString = atob(cropItem.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i);
        }
        const newBlob = new Blob([ab], {
            type: 'image/jpeg',
        });
        this.event = {} as ImgCropperEvent;
        this.event.target = {} as ImgCropperEventTarget;
        this.event.target.files = [] as any[];
        this.event.target.files.push(newBlob);
        this.imageChangedEvent = '';
        this.imageChangedEvent = this.event;
    }

    onSetProfilePic(index: number) {
        this.lstSelectedImages.forEach((value, cIndex) => {
            value.IsProfilePic = cIndex === index ? true : false;
        });
        // this.selectedImages.emit(this.lstSelectedImages);
        this.onChange(this.lstSelectedImages);
    }

    saveDescription() {
        // this.selectedImages.emit(this.lstSelectedImages);
        // this.onChange(this.lstSelectedImages);
    }

    orderChange(item: ImageCroppedEvent, index: number) {
        if (!!item && !!item?.Order && item?.Order > 0 && item.Order <= this.lstSelectedImages.length) {
            this.lstSelectedImages.splice(index, 1);
            this.lstSelectedImages.splice(item.Order - 1, 0, item);
            this.lstSelectedImages.forEach((fValue, fIndex) => { fValue.Order = fIndex + 1; });
        } else {
            const res = this.lstSelectedImages.filter(c => c.SNo === item.SNo);
            if (!!res?.length) {
                res[0].Order = index + 1;
            }
            this.alertMessage.warningToastr('Invalid');
        }
    }

    onDeleteSelectedImage(item: ImageCroppedEvent) {
        if (!!item && !!item?.ImageID) {
            this.confirmationService.confirm({
                message: 'Are you sure you want to delete ?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                rejectButtonStyleClass: 'p-button-text',
                acceptButtonStyleClass: 'p-button-primary',
                accept: () => {
                    if (item.ImageID) {
                        this.fileUploadService.deleteFile(item.ImageID)
                            .then(data => {
                                const result = data as boolean;
                                this.alertMessage.successToastr(
                                    'Deleted successfully'
                                );
                                const index = this.lstSelectedImages.indexOf(item);
                                if (this.cropItemIndex === index) {
                                    this.initCropper();
                                }
                                if (index !== -1) {
                                    this.lstSelectedImages.splice(index, 1);
                                    this.lstSelectedImages.forEach((fValue, fIndex) => { fValue.Order = fIndex + 1; });
                                    // this.selectedImages.emit(this.lstSelectedImages);
                                    this.onChange(this.lstSelectedImages);
                                }
                                this.lstSelectedImages.forEach((fValue, fIndex) => { fValue.Order = fIndex + 1; });
                                // this.selectedImages.emit(this.lstSelectedImages);
                                this.onChange(this.lstSelectedImages);
                                this.fileInput.nativeElement.value = '';
                            })
                            .catch(errors => {
                                this.cOps.showHttpError(errors);
                            });
                    }
                },
                reject: () => {
                    // this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
                }
            })
        } else {
            const index = this.lstSelectedImages.indexOf(item);
            if (this.cropItemIndex === index) {
                this.initCropper();
            }
            if (index !== -1) {
                this.lstSelectedImages.splice(index, 1);
                this.lstSelectedImages.forEach((fValue, fIndex) => { fValue.Order = fIndex + 1; });
                // this.selectedImages.emit(this.lstSelectedImages);
                this.onChange(this.lstSelectedImages);
            }
            this.lstSelectedImages.forEach((fValue, fIndex) => { fValue.Order = fIndex + 1; });
            // this.selectedImages.emit(this.lstSelectedImages);
            this.onChange(this.lstSelectedImages);
            this.fileInput.nativeElement.value = '';
        }
    }

    onCropImage(): void {
        const result = this.crop() as ImageCroppedEvent;
        this.lstSelectedImages.splice(this.cropItemIndex, 1, result);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes['cropper']) {
            this.setMaxSize();
            this.setCropperScaledMinSize();
            this.checkCropperPosition(false);
            this.doAutoCrop();
            this.cd.markForCheck();
        }
        if (changes['aspectRatio'] && this.imageVisible) {
            this.resetCropperPosition();
        }
    }

    private initCropper(): void {
        this.imageVisible = false;
        this.originalImage = null;
        this.safeImgDataUrl = 'data:image/png;base64,iVBORw0KGg'
            + 'oAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAU'
            + 'AAarVyFEAAAAASUVORK5CYII=';
        this.moveStart = {
            active: false,
            type: null,
            position: null,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            clientX: 0,
            clientY: 0
        };
        this.maxSize = {
            width: 0,
            height: 0
        };
        this.originalSize = {
            width: 0,
            height: 0
        };
        this.cropper.x1 = -100;
        this.cropper.y1 = -100;
        this.cropper.x2 = 10000;
        this.cropper.y2 = 10000;
    }

    private loadImageFile(file: File): void {
        const fileReader = new FileReader();
        fileReader.onload = (event: any) => {
            const imageType = file.type;
            if (this.isValidImageType(imageType)) {
                this.checkExifAndLoadBase64Image(event.target.result);
            } else {
                this.loadImageFailed.emit();
            }
        };
        fileReader.readAsDataURL(file);
    }

    private isValidImageType(type: string): boolean {
        return /image\/(png|jpg|jpeg|bmp|gif|tiff)/.test(type);
    }

    private checkExifAndLoadBase64Image(imageBase64: string): void {
        resetExifOrientation(imageBase64)
            .then((resultBase64: string) => this.fitImageToAspectRatio(resultBase64))
            .then((resultBase64: string) => this.loadBase64Image(resultBase64))
            .catch(() => this.loadImageFailed.emit());
    }

    private fitImageToAspectRatio(imageBase64: string): Promise<string> {
        return this.containWithinAspectRatio
            ? fitImageToAspectRatio(imageBase64, this.aspectRatio)
            : Promise.resolve(imageBase64);
    }

    private loadBase64Image(imageBase64: string): void {
        this.originalBase64 = imageBase64;
        this.safeImgDataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(imageBase64);
        this.originalImage = new Image();
        this.originalImage.onload = () => {
            this.originalSize.width = this.originalImage.width;
            this.originalSize.height = this.originalImage.height;
            this.cd.markForCheck();
        };
        this.originalImage.src = imageBase64;
    }

    imageLoadedInView(): void {
        if (this.originalImage != null) {
            this.imageLoaded.emit();
            this.setImageMaxSizeRetries = 0;
            setTimeout(() => this.checkImageMaxSizeRecursively());
        }
    }

    private checkImageMaxSizeRecursively(): void {
        if (this.setImageMaxSizeRetries > 40) {
            this.loadImageFailed.emit();
        } else if (this.sourceImage && this.sourceImage.nativeElement && this.sourceImage.nativeElement.offsetWidth > 0) {
            this.setMaxSize();
            this.setCropperScaledMinSize();
            this.resetCropperPosition();
            this.cropperReady.emit();
            this.cd.markForCheck();
        } else {
            this.setImageMaxSizeRetries++;
            setTimeout(() => {
                this.checkImageMaxSizeRecursively();
            }, 50);
        }
    }

    @HostListener('window:resize')
    onResize(): void {
        this.resizeCropperPosition();
        this.setMaxSize();
        this.setCropperScaledMinSize();
    }

    onRotateLeft(): void {
        this.transformBase64(8);
    }

    onRotateRight(): void {
        this.transformBase64(6);
    }

    onFlipHorizontal(): void {
        this.transformBase64(2);
    }

    onFlipVertical(): void {
        this.transformBase64(4);
    }

    private transformBase64(exifOrientation: number): void {
        if (this.originalBase64) {
            transformBase64BasedOnExifRotation(this.originalBase64, exifOrientation)
                .then((resultBase64: string) => this.fitImageToAspectRatio(resultBase64))
                .then((rotatedBase64: string) => this.loadBase64Image(rotatedBase64));
        }
    }

    private resizeCropperPosition(): void {
        const sourceImageElement = this.sourceImage.nativeElement;
        if (this.maxSize.width !== sourceImageElement.offsetWidth || this.maxSize.height !== sourceImageElement.offsetHeight) {
            this.cropper.x1 = this.cropper.x1 * sourceImageElement.offsetWidth / this.maxSize.width;
            this.cropper.x2 = this.cropper.x2 * sourceImageElement.offsetWidth / this.maxSize.width;
            this.cropper.y1 = this.cropper.y1 * sourceImageElement.offsetHeight / this.maxSize.height;
            this.cropper.y2 = this.cropper.y2 * sourceImageElement.offsetHeight / this.maxSize.height;
        }
    }

    public resetCropperPosition(): void {
        const sourceImageElement = this.sourceImage.nativeElement;
        if (!this.maintainAspectRatio) {
            this.cropper.x1 = 0;
            this.cropper.x2 = sourceImageElement.offsetWidth;
            this.cropper.y1 = 0;
            this.cropper.y2 = sourceImageElement.offsetHeight;
        } else if (sourceImageElement.offsetWidth / this.aspectRatio < sourceImageElement.offsetHeight) {
            this.cropper.x1 = 0;
            this.cropper.x2 = sourceImageElement.offsetWidth;
            const cropperHeight = sourceImageElement.offsetWidth / this.aspectRatio;
            this.cropper.y1 = (sourceImageElement.offsetHeight - cropperHeight) / 2;
            this.cropper.y2 = this.cropper.y1 + cropperHeight;
        } else {
            this.cropper.y1 = 0;
            this.cropper.y2 = sourceImageElement.offsetHeight;
            const cropperWidth = sourceImageElement.offsetHeight * this.aspectRatio;
            this.cropper.x1 = (sourceImageElement.offsetWidth - cropperWidth) / 2;
            this.cropper.x2 = this.cropper.x1 + cropperWidth;
        }
        this.doAutoCrop();
        this.imageVisible = true;
    }

    startMove(event: any, moveType: string, position: string | null = null): void {
        event.preventDefault();
        this.moveStart = {
            active: true,
            type: moveType,
            position,
            clientX: this.getClientX(event),
            clientY: this.getClientY(event),
            ...this.cropper
        };
    }

    @HostListener('document:mousemove', ['$event'])
    @HostListener('document:touchmove', ['$event'])
    moveImg(event: any): void {
        if (this.moveStart.active) {
            event.stopPropagation();
            event.preventDefault();
            if (this.moveStart.type === 'move') {
                this.move(event);
                this.checkCropperPosition(true);
            } else if (this.moveStart.type === 'resize') {
                this.resize(event);
                this.checkCropperPosition(false);
            }
            this.cd.detectChanges();
        }
    }

    private setMaxSize(): void {
        if (this.sourceImage) {
            const sourceImageElement = this.sourceImage.nativeElement;
            this.maxSize.width = sourceImageElement.offsetWidth;
            this.maxSize.height = sourceImageElement.offsetHeight;
            this.marginLeft = this.sanitizer.bypassSecurityTrustStyle('calc(50% - ' + this.maxSize.width / 2 + 'px)');
        }
    }

    private setCropperScaledMinSize(): void {
        if (this.originalImage) {
            this.setCropperScaledMinWidth();
            this.setCropperScaledMinHeight();
        } else {
            this.cropperScaledMinWidth = 20;
            this.cropperScaledMinHeight = 20;
        }
    }

    private setCropperScaledMinWidth(): void {
        this.cropperScaledMinWidth = this.cropperMinWidth > 0
            ? Math.max(20, this.cropperMinWidth / this.originalImage.width * this.maxSize.width)
            : 20;
    }

    private setCropperScaledMinHeight(): void {
        if (this.maintainAspectRatio) {
            this.cropperScaledMinHeight = Math.max(20, this.cropperScaledMinWidth / this.aspectRatio);
        } else if (this.cropperMinHeight > 0) {
            this.cropperScaledMinHeight = Math.max(20, this.cropperMinHeight / this.originalImage.height * this.maxSize.height);
        } else {
            this.cropperScaledMinHeight = 20;
        }
    }

    private checkCropperPosition(maintainSize = false): void {
        if (this.cropper.x1 < 0) {
            this.cropper.x2 -= maintainSize ? this.cropper.x1 : 0;
            this.cropper.x1 = 0;
        }
        if (this.cropper.y1 < 0) {
            this.cropper.y2 -= maintainSize ? this.cropper.y1 : 0;
            this.cropper.y1 = 0;
        }
        if (this.cropper.x2 > this.maxSize.width) {
            this.cropper.x1 -= maintainSize ? (this.cropper.x2 - this.maxSize.width) : 0;
            this.cropper.x2 = this.maxSize.width;
        }
        if (this.cropper.y2 > this.maxSize.height) {
            this.cropper.y1 -= maintainSize ? (this.cropper.y2 - this.maxSize.height) : 0;
            this.cropper.y2 = this.maxSize.height;
        }
    }

    @HostListener('document:mouseup')
    @HostListener('document:touchend')
    moveStop(): void {
        if (this.moveStart.active) {
            this.moveStart.active = false;
            this.doAutoCrop();
        }
    }

    private move(event: any) {
        const diffX = this.getClientX(event) - this.moveStart.clientX;
        const diffY = this.getClientY(event) - this.moveStart.clientY;

        this.cropper.x1 = this.moveStart.x1 + diffX;
        this.cropper.y1 = this.moveStart.y1 + diffY;
        this.cropper.x2 = this.moveStart.x2 + diffX;
        this.cropper.y2 = this.moveStart.y2 + diffY;
    }

    private resize(event: any): void {
        const diffX = this.getClientX(event) - this.moveStart.clientX;
        const diffY = this.getClientY(event) - this.moveStart.clientY;
        switch (this.moveStart.position) {
            case 'left':
                this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - this.cropperScaledMinWidth);
                break;
            case 'topleft':
                this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - this.cropperScaledMinWidth);
                this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - this.cropperScaledMinHeight);
                break;
            case 'top':
                this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - this.cropperScaledMinHeight);
                break;
            case 'topright':
                this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + this.cropperScaledMinWidth);
                this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - this.cropperScaledMinHeight);
                break;
            case 'right':
                this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + this.cropperScaledMinWidth);
                break;
            case 'bottomright':
                this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + this.cropperScaledMinWidth);
                this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + this.cropperScaledMinHeight);
                break;
            case 'bottom':
                this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + this.cropperScaledMinHeight);
                break;
            case 'bottomleft':
                this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - this.cropperScaledMinWidth);
                this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + this.cropperScaledMinHeight);
                break;
        }

        if (this.maintainAspectRatio) {
            this.checkAspectRatio();
        }
    }

    private checkAspectRatio(): void {
        let overflowX = 0;
        let overflowY = 0;

        switch (this.moveStart.position) {
            case 'top':
                this.cropper.x2 = this.cropper.x1 + (this.cropper.y2 - this.cropper.y1) * this.aspectRatio;
                overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
                overflowY = Math.max(0 - this.cropper.y1, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
            case 'bottom':
                this.cropper.x2 = this.cropper.x1 + (this.cropper.y2 - this.cropper.y1) * this.aspectRatio;
                overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
                overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : (overflowX / this.aspectRatio);
                }
                break;
            case 'topleft':
                this.cropper.y1 = this.cropper.y2 - (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
                overflowX = Math.max(0 - this.cropper.x1, 0);
                overflowY = Math.max(0 - this.cropper.y1, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x1 += (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
            case 'topright':
                this.cropper.y1 = this.cropper.y2 - (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
                overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
                overflowY = Math.max(0 - this.cropper.y1, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
            case 'right':
            case 'bottomright':
                this.cropper.y2 = this.cropper.y1 + (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
                overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
                overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
            case 'left':
            case 'bottomleft':
                this.cropper.y2 = this.cropper.y1 + (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
                overflowX = Math.max(0 - this.cropper.x1, 0);
                overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x1 += (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
        }
    }

    private doAutoCrop(): void {
        if (this.autoCrop) {
            this.crop();
        }
    }

    crop(outputType: OutputType = this.outputType): ImageCroppedEvent | Promise<ImageCroppedEvent> | null {
        if (this.sourceImage.nativeElement && this.originalImage != null) {
            this.startCropImage.emit();
            const imagePosition = this.getImagePosition();
            const width = imagePosition.x2 - imagePosition.x1;
            const height = imagePosition.y2 - imagePosition.y1;
 
            const cropCanvas = this.document.createElement('canvas') as HTMLCanvasElement;
            cropCanvas.width = width;
            cropCanvas.height = height;

            const ctx = cropCanvas.getContext('2d');
            if (ctx) {
                if (this.backgroundColor != null) {
                    ctx.fillStyle = this.backgroundColor;
                    ctx.fillRect(0, 0, width, height);
                }
                ctx.drawImage(
                    this.originalImage,
                    imagePosition.x1,
                    imagePosition.y1,
                    width,
                    height,
                    0,
                    0,
                    width,
                    height
                );
                const output = { width, height, imagePosition, cropperPosition: { ...this.cropper } };
                const resizeRatio = this.getResizeRatio(width, height);
                if (resizeRatio !== 1) {
                    output.width = Math.round(width * resizeRatio);
                    output.height = this.maintainAspectRatio
                        ? Math.round(output.width / this.aspectRatio)
                        : Math.round(height * resizeRatio);
                    resizeCanvas(cropCanvas, output.width, output.height);
                }
                return this.cropToOutputType(outputType, cropCanvas, output);
            }
        }
        return null;
    }

    private getImagePosition(): CropperPosition {
        const sourceImageElement = this.sourceImage.nativeElement;
        const ratio = this.originalSize.width / sourceImageElement.offsetWidth;
        return {
            x1: Math.round(this.cropper.x1 * ratio),
            y1: Math.round(this.cropper.y1 * ratio),
            x2: Math.min(Math.round(this.cropper.x2 * ratio), this.originalSize.width),
            y2: Math.min(Math.round(this.cropper.y2 * ratio), this.originalSize.height)
        };
    }

    private cropToOutputType(outputType: OutputType, cropCanvas: HTMLCanvasElement, output: ImageCroppedEvent): ImageCroppedEvent | Promise<ImageCroppedEvent> {
        switch (outputType) {
            case 'file':
                return this.cropToFile(cropCanvas)
                    .then((result: Blob | null) => {
                        output.file = result;
                        this.imageCropped.emit(output);
                        return output;
                    });
            case 'both':
                output.base64 = this.cropToBase64(cropCanvas);
                return this.cropToFile(cropCanvas)
                    .then((result: Blob | null) => {
                        output.file = result;
                        this.imageCropped.emit(output);
                        return output;
                    });
            default:
                output.base64 = this.cropToBase64(cropCanvas);
                this.imageCropped.emit(output);
                return output;
        }
    }

    private cropToBase64(cropCanvas: HTMLCanvasElement): string {
        return cropCanvas.toDataURL('image/' + this.format, this.getQuality());
    }

    private cropToFile(cropCanvas: HTMLCanvasElement): Promise<Blob | null> {
        return new Promise((resolve) => {
            cropCanvas.toBlob(
                (result: Blob | null) => this.zone.run(() => resolve(result)),
                'image/' + this.format,
                this.getQuality()
            );
        });
    }

    private getQuality(): number {
        return Math.min(1, Math.max(0, this.imageQuality / 100));
    }

    private getResizeRatio(width: number, height: number): number {
        if (this.resizeToWidth > 0) {
            if (!this.onlyScaleDown || width > this.resizeToWidth) {
                return this.resizeToWidth / width;
            }
        } else if (this.resizeToHeight > 0) {
            if (!this.onlyScaleDown || height > this.resizeToHeight) {
                return this.resizeToHeight / height;
            }
        }
        return 1;

    }

    private getClientX(event: any): number {
        return event.touches && event.touches[0] && event.touches[0].clientX || event.clientX;
    }

    private getClientY(event: any): number {
        return event.touches && event.touches[0] && event.touches[0].clientY || event.clientY;
    }
}
