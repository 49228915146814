export enum ControlType {
  NotDefined = 0,
  Text = 1,
  Bool = 2,
  MultilineText = 3,
  Date = 4
}

export namespace ControlType {
  export function values() {
    return Object.keys(ControlType).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}
