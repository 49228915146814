export enum MessageStatus {
  UnRead = 0,
  Read = 1
}

export namespace MessageStatus {
  export function values() {
    return Object.keys(MessageStatus).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}
