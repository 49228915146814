<div class="ng-image-container">
  <div class="choose-button">
    <button *ngIf="multiple" type="button" (click)="fileInput.click()" class="stroked-button">
      <span class="material-icons">add_photo_alternate</span>
      <span class="mx-2 semibold">Upload Photo(s)</span>
      <input #fileInput type="file" accept="image/*" multiple (change)="fileChangeEvent($event)"
        style="display:none;" />
    </button>

    <button class="stroked-button" *ngIf="!multiple" [disabled]="!!lstSelectedImages.length" type="button" (click)="fileInput.click()">
      <span class="material-icons">add_photo_alternate</span>
      <span class="mx-2 semibold">Upload Photo</span>
      <input #fileInput type="file" accept="image/*" (change)="fileChangeEvent($event)" style="display:none;" />
    </button>

  </div>

  <ng-container *ngIf="!!lstSelectedImages?.length">
  <ul class="s-list">
    
    <li *ngFor="let imageItem of lstSelectedImages;let i = index"
      [ngClass]="{'disable': cropItemIndex != i && imageVisible}">
      <img [src]="imageItem?.base64">
      <div class="action-buttons">
        <ng-container *ngIf="enableProfilePicSelection">
          <button class="stroked-button white-content" *ngIf="!imageItem.IsProfilePic" (click)="onSetProfilePic(i)">
            <span class="material-icons">add_photo_alternate</span>
          </button>
          <button class="stroked-button white-content" *ngIf="imageItem.IsProfilePic">
            <span class="material-icons">add_photo_alternate</span>
          </button>
        </ng-container>

        <button class="stroked-button white-content" (click)="onCropSelectedImage(imageItem.base64, i)">
          <span class="material-icons">crop</span>
        </button>

        <button class="stroked-button white-content" (click)="onDeleteSelectedImage(imageItem)">
          <span class="material-icons">delete</span>
        </button>
      </div>
    </li>
  </ul></ng-container>


  <div class="fr la-s-s">
    <div class="ngc-container">
      <div class="ngc-header"  *ngIf="imageVisible">
        <button class="stroked-button" (click)="onRotateLeft()">
          <span class="material-icons">rotate_left</span>
        </button>
        <button class="stroked-button" (click)="onRotateRight()">
          <span class="material-icons">rotate_right</span>
        </button>
        <button class="stroked-button" (click)="onFlipHorizontal()">
          <span class="material-icons">flip</span>
        </button>
        <button class="stroked-button" (click)="onFlipVertical()">
          <span class="material-icons" style="transform: rotate(90deg);">flip</span>
        </button>
        <!-- 
                <mat-button-toggle-group aria-label="cropRatio" class="crop-toggle-group" [(ngModel)]="aspectRatio" (ngModelChange)="ratioChanged(aspectRatio)">
                    <mat-button-toggle *ngFor="let item of lstAspectRatio" [value]="item.Value">{{item.Name}}</mat-button-toggle>
                </mat-button-toggle-group> -->
      </div>

      <div class="host" [style.background]="imageVisible && backgroundColor">
        <div>
          <img #sourceImage class="source-image" [src]="safeImgDataUrl"
            [style.visibility]="imageVisible ? 'visible' : 'hidden'" (load)="imageLoadedInView()" />
        </div>
        <div class="cropper" *ngIf="imageVisible" [class.rounded]="roundCropper" [style.top.px]="cropper.y1"
          [style.left.px]="cropper.x1" [style.width.px]="cropper.x2 - cropper.x1"
          [style.height.px]="cropper.y2 - cropper.y1" [style.margin-left]="alignImage === 'center' ? marginLeft : null"
          [style.visibility]="imageVisible ? 'visible' : 'hidden'">
          <div (mousedown)="startMove($event, 'move')" (touchstart)="startMove($event, 'move')" class="move">&nbsp;
          </div>
          <span class="resize topleft" (mousedown)="startMove($event, 'resize', 'topleft')"
            (touchstart)="startMove($event, 'resize', 'topleft')"><span class="square"></span></span>
          <span class="resize top"><span class="square"></span></span>
          <span class="resize topright" (mousedown)="startMove($event, 'resize', 'topright')"
            (touchstart)="startMove($event, 'resize', 'topright')"><span class="square"></span></span>
          <span class="resize right"><span class="square"></span></span>
          <span class="resize bottomright" (mousedown)="startMove($event, 'resize', 'bottomright')"
            (touchstart)="startMove($event, 'resize', 'bottomright')"><span class="square"></span></span>
          <span class="resize bottom"><span class="square"></span></span>
          <span class="resize bottomleft" (mousedown)="startMove($event, 'resize', 'bottomleft')"
            (touchstart)="startMove($event, 'resize', 'bottomleft')"><span class="square"></span></span>
          <span class="resize left"><span class="square"></span></span>
          <span class="resize-bar top" (mousedown)="startMove($event, 'resize', 'top')"
            (touchstart)="startMove($event, 'resize', 'top')"></span>
          <span class="resize-bar right" (mousedown)="startMove($event, 'resize', 'right')"
            (touchstart)="startMove($event, 'resize', 'right')"></span>
          <span class="resize-bar bottom" (mousedown)="startMove($event, 'resize', 'bottom')"
            (touchstart)="startMove($event, 'resize', 'bottom')"></span>
          <span class="resize-bar left" (mousedown)="startMove($event, 'resize', 'left')"
            (touchstart)="startMove($event, 'resize', 'left')"></span>
        </div>
      </div>

      <div class="ngc-footer"  *ngIf="imageVisible">
        <button class="stroked-button" [disabled]="safeImgDataUrl === null || safeImgDataUrl === 'undefined'"
          (click)="onCropImage()">
          <span class="material-icons">crop</span>
        </button>
        <button class="stroked-button m-lr-05" (click)="onCloseCropper()" type="button">
          Close
        </button>
      </div>
    </div>
  </div>
</div>