import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { ChatMessages, ChatRequestUser } from '../interfaces';
import { CommonOperations } from '../helpers/common-operations';
import { environment } from '../../environments/environment';
import { HttpBaseService } from '../core/services/http-base.service';
import { TokenConfiguration } from '../helpers/web-configuration';
import { StorageClientService } from '../core/services/storage-client.service';

const DEFAULT_ARRAY = [] as any;

@Injectable({
  providedIn: 'root'
})
export class ChatRetrieverService extends HttpBaseService implements OnDestroy {

  protected endpoint: string = '';
  chatList$: BehaviorSubject<ChatRequestUser[]> = new BehaviorSubject<ChatRequestUser[]>(DEFAULT_ARRAY);

  constructor(
    protected http: HttpClient,
    protected httpBackendInterceptor: HttpClient,
    protected httpBackend: HttpBackend,
    private storage: StorageClientService,
    private cOps: CommonOperations
  ) {
    super();
    this.httpBackendInterceptor = new HttpClient(this.httpBackend);
  }

  getChatUsers(profileId: string) {
    this.endpoint = `${environment.apiUrl}/ChatRetriever/GetChatUsers/${profileId}`;
    this.storage.get('ACCESS_TOKEN').then((access_token: any) => {

      let headerData = new HttpHeaders();
      headerData = headerData.set('Token', access_token || TokenConfiguration.AccessToken);
      this.requestOptions.headers = headerData;
      super.getSilent<ChatRequestUser[]>().subscribe((response: ChatRequestUser[]) => {
        response?.forEach(c => c.ProfileImage = this.cOps.getProfileImageChatApp(c));
        this.chatList$.next(response);
      });
    });
  }

  getUnReadMessages(fromProfileId: string, toProfileId: string): Observable<number> {
    this.endpoint = `${environment.apiUrl}/ChatRetriever/GetUnReadMessages/${fromProfileId}/${toProfileId}`;
    let headerData = new HttpHeaders();
    headerData = headerData.set('Token', TokenConfiguration.AccessToken);
    this.requestOptions.headers = headerData;
    return super.getSilent<number>()
  }

  getRecentMessages(fromProfileId: string, toProfileId: string): Observable<ChatMessages[]> {
    this.endpoint = `${environment.apiUrl}/ChatRetriever/GetRecentMessages/${fromProfileId}/${toProfileId}`;
    let headerData = new HttpHeaders();
    headerData = headerData.set('Token', TokenConfiguration.AccessToken);
    this.requestOptions.headers = headerData;
    return super.getSilent<ChatMessages[]>()
  }

  getPreviousMessages(profileId: string): Observable<ChatMessages[]> {
    this.endpoint = `${environment.apiUrl}/ChatRetriever/GetRecentMessages/${profileId}`;
    let headerData = new HttpHeaders();
    headerData = headerData.set('Token', TokenConfiguration.AccessToken);
    this.requestOptions.headers = headerData;
    return super.getSilent<ChatMessages[]>()
  }

  ngOnDestroy(): void {
    if (!!this.chatList$) {
      this.chatList$.unsubscribe();
      this.chatList$ = new BehaviorSubject<ChatRequestUser[]>(DEFAULT_ARRAY);
    }
  }
}
