import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { RegUser, PartnerPreferenceMatch, RegUserInformation } from '../interfaces';
import { CommonOperations } from '../helpers/common-operations';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpBaseService } from '../core/services/http-base.service';
import { TokenConfiguration } from '../helpers/web-configuration';
import { StorageClientService } from '../core/services/storage-client.service';

const DEFAULT_ARRAY: any = [];
const DEFAULT_OBJECT: any = {};

@Injectable({
  providedIn: 'root'
})
export class UserSearchService extends HttpBaseService implements OnDestroy {

  protected endpoint: string = '';

  similarProfiles$: BehaviorSubject<RegUserInformation> = new BehaviorSubject<RegUserInformation>(DEFAULT_OBJECT);
  similarProfilesInfiniteList$: BehaviorSubject<RegUserInformation> = new BehaviorSubject<RegUserInformation>(DEFAULT_OBJECT);
  
  profilesHighlighters$: BehaviorSubject<RegUser[]> = new BehaviorSubject<RegUser[]>(DEFAULT_ARRAY);
  profilesHighlightersAll$: BehaviorSubject<RegUser[]> = new BehaviorSubject<RegUser[]>(DEFAULT_ARRAY);
  
  interestedProfiles$: BehaviorSubject<RegUser[]> = new BehaviorSubject<RegUser[]>(DEFAULT_OBJECT);
  interestedProfilesAll$: BehaviorSubject<RegUser[]> = new BehaviorSubject<RegUser[]>(DEFAULT_OBJECT);
  
  constructor(
    protected http: HttpClient,
    protected httpBackendInterceptor: HttpClient,
    protected httpBackend: HttpBackend,
    private storage: StorageClientService,
    private cOps: CommonOperations
  ) { 
    super();
    this.httpBackendInterceptor = new HttpClient(this.httpBackend); 
  }

  getSimilarProfiles(fullResults: boolean, pageNbr: number, pageSize: number): void {
    this.endpoint = `${environment.apiUrl}/UserSearch/GetSimilarProfiles/${fullResults}/${pageNbr}/${pageSize}`;
        
    super.get<RegUserInformation>().subscribe((response: RegUserInformation) => {
      response.LstUsers?.forEach(user => user = this.cOps.prepareProfileCard(user));
      this.similarProfiles$.next(response);
    });
  }
  
  getSimilarProfilesInfiniteScroll(fullResults: boolean, pageNbr: number, pageSize: number): void {
    this.endpoint = `${environment.apiUrl}/UserSearch/GetSimilarProfiles/${fullResults}/${pageNbr}/${pageSize}`;
    this.storage.get('ACCESS_TOKEN').then((access_token: any) => {

      let headerData = new HttpHeaders();
      headerData = headerData.set('Token', access_token || TokenConfiguration.AccessToken);
      this.requestOptions.headers = headerData;
        
      super.getSilent<RegUserInformation>().pipe(
        map((response: RegUserInformation) => {
          response.LstUsers?.forEach(user => user = this.cOps.prepareProfileCard(user));
          this.similarProfilesInfiniteList$.next(response);
        })
      ).subscribe();
    });    
  }
  
  getPartnerPreferenceMatch(profileId: string): Observable<PartnerPreferenceMatch> {
    this.endpoint = `${environment.apiUrl}/UserSearch/GetPartnerPreferenceMatch/${profileId}`;
    return super.get<PartnerPreferenceMatch>();
  }

  getProfileHighLighters(gender: number, fullResults: boolean): void {
    this.endpoint = `${environment.apiUrl}/UserSearch/GetProfileHighLighters/${gender}/${fullResults}`;
    super.get<RegUser[]>().pipe(
      map((response: RegUser[]) => {
        if (fullResults) {
          response?.forEach(user => user = this.cOps.prepareProfileCard(user));
          this.profilesHighlighters$.next(response);
        } else if (!fullResults) {
          response?.forEach(user => user = this.cOps.prepareProfileCard(user));
          this.profilesHighlightersAll$.next(response);
        }
      })
    ).subscribe();
  }

  getInterestedProfiles(fullResults: boolean): void {
    this.endpoint = `${environment.apiUrl}/UserSearch/GetInterestedProfiles/${fullResults}`;
    super.get<RegUser[]>().pipe(
      map((response: RegUser[]) => {
        if (fullResults) {
          response?.forEach(user => user = this.cOps.prepareProfileCard(user));
          this.profilesHighlighters$.next(response);
        } else if (!fullResults) {
          response?.forEach(user => user = this.cOps.prepareProfileCard(user));
          this.profilesHighlightersAll$.next(response);
        }
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.similarProfiles$?.unsubscribe();
    this.similarProfilesInfiniteList$?.unsubscribe();
    this.profilesHighlighters$?.unsubscribe();
    this.profilesHighlightersAll$?.unsubscribe();
    this.interestedProfiles$?.unsubscribe();
    this.interestedProfilesAll$?.unsubscribe();
      
    this.similarProfiles$ = new BehaviorSubject<RegUserInformation>(DEFAULT_OBJECT);
    this.similarProfilesInfiniteList$ = new BehaviorSubject<RegUserInformation>(DEFAULT_OBJECT);
    this.profilesHighlighters$ = new BehaviorSubject<RegUser[]>(DEFAULT_ARRAY);
    this.profilesHighlightersAll$ = new BehaviorSubject<RegUser[]>(DEFAULT_ARRAY);
    this.interestedProfiles$ = new BehaviorSubject<RegUser[]>(DEFAULT_OBJECT);
    this.interestedProfilesAll$ = new BehaviorSubject<RegUser[]>(DEFAULT_OBJECT);
  }
}
