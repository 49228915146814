import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { AuthState } from "../../interfaces";
import * as AuthActions from '../../state/auth/auth.actions';
import { STATE_KEYS } from "../../core/utilities/constants";

export const initialAuthState: AuthState = {
    Email: null,
    Password: null,
    RememberMe: true,
    IsOTPLogin: false,
    AccessToken: null,
    RefreshToken: null,
    Role: null,
    User: null,
    AccountInfo: null,
    UserInfo: null,
    UserPage: {
        filters: undefined,
        users: undefined
    },
    LoginError: null
}

const _authReducer = createReducer(
    initialAuthState,
    on(AuthActions.loginSuccess, (state, action) => {
        return {
            ...state,
            Email: action.email,
            Password: action.password,
            AccessToken: action.loginSuccessResponse.AccessToken,
            RefreshToken: action.loginSuccessResponse.RefreshToken
        }
    }),
    // on(AuthActions.otpLoginSuccess, (state, action) => {
    //     return {
    //         ...state,
    //         AccessToken: action.loginSuccessResponse.AccessToken,
    //         RefreshToken: action.loginSuccessResponse.RefreshToken
    //     }
    // }),
    on(AuthActions.checkAccessSuccess, (state, { role }) => ({ ...state, Role: role })),
    on(AuthActions.saveLoggedUserState, (state, { user }) => {
        return {
            ...state,
            User: user,
            UserInfo: {
                SNo: user.SNo,
                UserProfileSNo: user.SNo,
                UserProfileID: user.ProfileID || "",
                Name: user.Name,
                EmailAddress: user.Email || "",
                Description: user.Name,
                IsActive: user.IsActive || false,
                IsFromQuickRegistration: false,
                IsEmailVerified: user.IsEmailVerified || false,
                IsMobileVerified: user.IsMobileVerified || false,
                ValidUpto: JSON.stringify(user.ValidUpto) || JSON.stringify(new Date()),
                ProfilePercentage: user.ProfilePercentage || 0,
                ImageID: "",
                PermissionMode: 1
              }
        }
    }),
    on(AuthActions.saveAccountInfo, (state, { accountInfo }) => ({ ...state, AccountInfo: accountInfo })),
    on(AuthActions.updateAccessToken, (state, { accessToken, refreshToken }) => ({ ...state, AccessToken: accessToken, RefreshToken: refreshToken })),
    on(AuthActions.updateLoggedUserState, (state, action) => ({ ...state, User: action.user })),
    on(AuthActions.loginRequest, (state) => {
        return {
            ...state,
            LoginError: null
        }
    }),
    on(AuthActions.loginFailure, (state, { error }) => {
        return {
            ...state,
            AccessToken: null,
            RefreshToken: null,
            User: null,
            Role: null,
            LoginError: error
        }
    }),
    on(AuthActions.saveOrderInfoAndUserInfo, (state, { orderInfo, userInfo }) => ({ ...state, OrderInfo: orderInfo, UserInfo: userInfo })),
    on(AuthActions.fetchUserPageData, (state, { filters }) => ({ ...state, UserPage: { ...state.UserPage, filters: filters }})),
    on(AuthActions.saveUserPageData, (state, { users }) => ({ ...state, UserPage: { ...state.UserPage, users: users } })),
    on(AuthActions.logOut, (state) => ({ ...initialAuthState }))
)

// meta reducer
export function authReducer(state: any, action: any) {
    return _authReducer(state, action);
}

// auth -> key declared in login module.ts
export const selectAuthState = createFeatureSelector<AuthState>(STATE_KEYS.AUTH);

// fetch user state from auth
export const selectUser = createSelector(selectAuthState, state => state.User);
export const loginFailureSelector = createSelector(selectAuthState, state => state.LoginError);

// fetch
export const isLoggedIn = createSelector(selectAuthState, state => !!state?.User);

export const accountInfoSelector = createSelector(selectAuthState, state => state.AccountInfo);

export const acessToken = createSelector(selectAuthState, state => state.AccessToken);

export const refreshToken = createSelector(selectAuthState, state => state.RefreshToken);

export const userInfoSelector = createSelector(selectAuthState, state => state.UserInfo);

export const usersPageFiltersSelector = createSelector(selectAuthState, state => state.UserPage.filters);
export const usersPageUsersSelector = createSelector(selectAuthState, state => state.UserPage.users);
