
<div #swiper class="swiper profileImageSwiper">
    <div class="swiper-wrapper">
        @for (item of pics; track $index) {
            <div class="swiper-slide">
                <div class="slide-image">
                    <div class="image shadow" *profileImage="user; let profileImage = profileImage">
                        <img loading="lazy" fill fetchpriority="auto" [ngSrc]="profileImage" alt="image" class="fadeinup">
                      </div>
                </div>
            </div>
        }
    </div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
</div>