import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { CommonOperations } from '../helpers/common-operations';
import { TokenConfiguration } from '../helpers/web-configuration';
import { HttpBaseService } from '../core/services/http-base.service';
import { StorageClientService } from '../core/services/storage-client.service';
import { DEFAULT_USER_ICONS, GENDER, LOCAL_STORAGE_KEY } from '../core/utilities/constants';
import { RegUser, UserCredentialModel, TokenHolder, RegEmployeeInfo, AuthState } from '../interfaces';

import * as AuthActions from '../state/auth/auth.actions';


@Injectable({
  providedIn: 'root'
})
export class LoginOperationsService extends HttpBaseService {
  
  protected endpoint: string = '';
  
  private cOps = inject(CommonOperations);
  private storage = inject(StorageClientService);

  constructor(
    protected http: HttpClient,
    protected httpBackend: HttpBackend,
    public dialogService: DialogService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private authStore: Store<AuthState>,
    ) {
    super();
  }
  
  verifyAccessToken() {
    this.endpoint = `${environment.apiUrl}/EmployeeLogin/Authorize`;
    return super.get<boolean>()
  }

  authenticate() {
    forkJoin([this.checkAccess(), this.fetchLoggedUserProfile()]).subscribe({
      next: (response) => {
        if (!!response[1]?.Role?.Id) {
          switch (response[1].Role.Id) {
            case 1:
              this.router.navigate(['/admin/dashboard']);
              break;
            default: break;
          }
        }
      },
      error: error => {
        this.authStore.dispatch(AuthActions.logOut());
        this.cOps.showHttpError(error, null);
      }
    });   
  }

  login(loginModel: UserCredentialModel): Observable<TokenHolder> {
    this.endpoint = `${environment.apiUrl}/EmployeeLogin/ValidateLogin`;
    return super.post<UserCredentialModel, TokenHolder>('', loginModel);
  }
  
  checkAccess(): Observable<any> {
    this.endpoint = `${environment.apiUrl}/AccessProvider/CheckEmployeeAccess`;
    return super.get<string>().pipe(mergeMap(async response => {
      this.storage.set(LOCAL_STORAGE_KEY.USER_ROLE, response);
      return response;
    }))
  }
  
  fetchLoggedUserProfile(): Observable<RegEmployeeInfo> {
    this.endpoint = `${environment.apiUrl}/EmployeeOperations/GetEmployeeInfo`;
    return super.get<RegEmployeeInfo>();
  }
  
  getMobileOTP(mobileNumber: number): Observable<boolean> {
    this.endpoint = `${environment.apiUrl}/LoginOperations/GetMobileOTP/${mobileNumber}`;
    return super.get<boolean>();
  }

  refreshToken() {
    this.endpoint = `${environment.apiUrl}/EmployeeLogin/RefreshToken`;
    return super.get<TokenHolder>().pipe(
      tap((token: TokenHolder) => {
        const tokenHolder = token as TokenHolder;
        this.storage.clear();
        this.storage.set(LOCAL_STORAGE_KEY.ACCESS_TOKEN, tokenHolder.AccessToken);
        this.storage.set(LOCAL_STORAGE_KEY.REFRESH_TOKEN, tokenHolder.RefreshToken);
        TokenConfiguration.AccessToken = tokenHolder.AccessToken;
        TokenConfiguration.RefreshToken = tokenHolder.RefreshToken;
      }));
  }
  
  storeToken(tokenHolder: TokenHolder, email: string, password: string): void {
    this.storage.clear();
    this.storage.set(LOCAL_STORAGE_KEY.ACCESS_TOKEN, tokenHolder.AccessToken);
    this.storage.set(LOCAL_STORAGE_KEY.REFRESH_TOKEN, tokenHolder.RefreshToken);
    this.storage.set(LOCAL_STORAGE_KEY.EMAIL, email);
    this.storage.set(LOCAL_STORAGE_KEY.PASSWORD, password);
  }

  storeUserRole(role: string, email: string, password: string): void {
    this.storage.set(LOCAL_STORAGE_KEY.USER_ROLE, role);
    this.storage.set(LOCAL_STORAGE_KEY.EMAIL, email);
    this.storage.set(LOCAL_STORAGE_KEY.PASSWORD, password);
  }

  storeUser(user: RegUser) {
    this.storage.set(LOCAL_STORAGE_KEY.USER, JSON.stringify(user));
  }
  
  validatePassword(password: string) : Promise<boolean> {
    return this.storage.get(LOCAL_STORAGE_KEY.PASSWORD).then((val: any) => atob(val) == password)
  }

  getImage({ imageId, gender = GENDER.MALE }: { imageId: string, gender?: string }): any {
    this.endpoint = `${environment.apiUrl}/Image/Get/${imageId}`;
    this.requestOptions.responseType = 'arraybuffer';

    return super.get<any>().pipe(
      map(async response => {
        this.requestOptions.responseType = undefined;
        if (!response) {
          return gender == GENDER.FEMALE ? DEFAULT_USER_ICONS.FEMALE_ICON : DEFAULT_USER_ICONS.MALE_ICON
        }
        return response
      }),
      catchError(async (e: any) => {
        this.requestOptions.responseType = undefined;
        //do your processing here
        return DEFAULT_USER_ICONS.BROKEN_IMAGE_ICON;
      })
    )
  }
  
  logout(): void {
    this.storage.remove(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    this.storage.remove(LOCAL_STORAGE_KEY.EMAIL);
    this.storage.remove(LOCAL_STORAGE_KEY.PASSWORD);
    this.storage.remove(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
    this.storage.remove(LOCAL_STORAGE_KEY.USER);
    this.storage.remove(LOCAL_STORAGE_KEY.USER_ROLE);
    this.storage.clear();

    TokenConfiguration.AccessToken = '';
    TokenConfiguration.RefreshToken = '';
    TokenConfiguration.RefreshInitiator = false;
    
    this.dialogService.dialogComponentRefMap.forEach(dialog => {
      dialog.destroy();
    });
  }
}
