import { Injectable } from '@angular/core';
import { RegUser } from '../interfaces/reg-user';
import { DatePipe } from '@angular/common';
import { RegUserPics, HttpError, ErrorType, RegOffer, RegReligiousOffer, ChatRequestUser } from '../interfaces';
import { SeoUserInfo } from '../interfaces/seo-user-info';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { TOASTR_MESSAGE, TOASTR_SEVERITY, TOASTR_SUMMARY } from '../core/utilities/constants';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonOperations {
  currentUserInfo: RegUser = {} as RegUser;

  constructor(
    public datepipe: DatePipe,
    private messageService: MessageService
  ) { }
  convertSpacesToHyphen(value: string): string {
    const separators = [' ', '\\+', '-', '\\(', '\\)', '\\*', '/', ':', '\\?'];
    const splitArr = value.split(new RegExp(separators.join('|'), 'g'));
    const result = splitArr.filter(Boolean).join('-');
    return result.toLowerCase();
  }

  showHttpError(httpError: HttpError, formGroup?: FormGroup | null) {
    let errorMessage: string = "";
    if (!!httpError) {
      if (typeof httpError === 'string' || httpError instanceof String) {
        errorMessage = httpError.toString();
      } else {
        if (httpError.error != null && httpError.status === 400 && !!formGroup) {
          httpError.error.forEach((element: any) => {
            for (const key in formGroup?.controls) {
              if (formGroup?.controls.hasOwnProperty(key)) {
                if (key === element.FieldName) {
                  formGroup.controls[key].setErrors({
                    serverError: element.Message
                  });
                }
              }
            }
          });
        } else {
          const error = httpError.error as any;
          if (typeof error === 'undefined' || error === null) {
            errorMessage = 'Error Occurred';
          } else if (
            Object.prototype.toString.call(error) === '[object ProgressEvent]'
          ) {
            errorMessage = httpError.statusText;
          } else if (
            Object.prototype.toString.call(error) === '[object Array]' &&
            error.length > 0
          ) {
            const lstErrors = error as ErrorType[];
            errorMessage = "";
            lstErrors?.forEach((c) => {
              this.messageService.add({
                severity: TOASTR_SEVERITY.ERROR,
                summary: TOASTR_SUMMARY.ERROR,
                detail: c.Message
              });
            });
          } else if (
            Object.prototype.toString.call(error) === '[object ErrorType]'
          ) {
            errorMessage = error.Message;
          } else if (
            Object.prototype.toString.call(error) === '[object Object]'
          ) {
            errorMessage = error.Message;
          } else {
            errorMessage = error;
          }
        }
      }
    }

    if (!!errorMessage) {
      this.messageService.add({
        severity: TOASTR_SEVERITY.ERROR,
        summary: TOASTR_SUMMARY.ERROR,
        detail: errorMessage
      });
    }

  }

  getProfileImageFromRelatedUserPics(user: any): string | undefined {
    const result = this.updateUserPicsUrl(user);
    if (!!result) {
      const user1 = result as RegUser;
      if (!user1.IsRequestViewEnabled) {
        if (!!user1.RelatedUserPics?.length) {
          if (user1.RelatedUserPics?.some(c => c.IsProfilePic === true)) {
            return user1.RelatedUserPics.filter(d => d.IsProfilePic === true)[0].Url;
          } else {
            return !!user1?.RelatedUserPics?.length ? user1.RelatedUserPics[0].Url : '';
          }
        } else {
          switch (user1.Gender) {
            case 'Female':
              return 'assets/images/women-ic.png';
              break;
            case 'Male':
              return 'assets/images/men-ic.png';
              break;
            default:
              return 'assets/images/men-ic.png';
              break;
          }
        }
      } else {
        return 'assets/svg/shield.svg';
      }
    }
    return ''
  }

  getImage(picRetreiverID: string): string {
    return picRetreiverID ? environment.apiUrl + '/Image/Get/' + picRetreiverID : "";
  }

  getOfferImage(offer: RegOffer): string {
    return environment.apiUrl + '/Image/Get/' + offer.PICRetrieveID;
  }

  getReligiousOfferImage(offer: RegReligiousOffer): string {
    if (!!offer && !!offer.RegOffer) {
      return environment.apiUrl + '/Image/Get/' + offer.RegOffer.PICRetrieveID;
    } else {
      return '';
    }
  }

  getProfileImage(user: RegUser): string | undefined {
    const result = this.updateUserPicsUrl(user);
    if (!!result) {
      const user1 = result as RegUser;
      if (!user1.IsRequestViewEnabled) {
        if (!!user1?.RelatedUserPics?.length) {

          if (user1.RelatedUserPics?.some(c => c.IsProfilePic === true)) {
            const pics = user1.RelatedUserPics.filter(d => d.IsProfilePic === true);
            return !!pics?.length ? pics[0].Url : "";
          } else {
            return !!user1.RelatedUserPics?.length ? user1.RelatedUserPics[0]?.Url : '';
          }
        } else {
          switch (user1.Gender) {
            case 'Female':
              return 'assets/images/women-ic.png';
              break;
            case 'Male':
              return 'assets/images/men-ic.png';
              break;
            default:
              return 'assets/images/men-ic.png';
              break;
          }
        }
      } else {
        return 'assets/svg/shield.svg';
      }
    }
    return ''
  }

  getProfileImageChatApp(user: ChatRequestUser): string {
    if (!!user) {
      if (!!user.ImageId) {
        return environment.apiUrl + '/Image/Get/' + user.ImageId;
      } else {
        switch (user.Gender) {
          case 'Female':
            return 'assets/images/women-ic.png';
            break;
          case 'Male':
            return 'assets/images/men-ic.png';
            break;
          default:
            return 'assets/images/men-ic.png';
            break;
        }
      }
    }

    return '';
  }

  getProfileImageSeo(user: SeoUserInfo): string {
    if (!!user) {
      if (!!user.RelatedUserPics?.length) {
        return environment.apiUrl + '/Image/Get/' + user.RelatedUserPics[0].PICRetrieveID;
      } else {
        switch (user.Gender) {
          case 'Female':
            return 'assets/images/women-ic.png';
            break;
          case 'Male':
            return 'assets/images/men-ic.png';
            break;
          default:
            return 'assets/images/men-ic.png';
            break;
        }
      }
    }
    return ''
  }

  convertToUserPics(images: any): any {
    const userPics = [] as RegUserPics[];
    if (images !== null) {
      if (
        Object.prototype.toString.call(images) === '[object Array]' &&
        images.length > 0
      ) {
        images.forEach((image: any) => {
          const userPic = {} as RegUserPics;
          userPic.Name =
            image.file != null && image.file !== 'undefined'
              ? image.file.name
              : '';
          userPic.PICRetrieveID = image.ImageID;
          userPic.IsProfilePic = image.IsProfilePic;
          userPics.push(userPic);
        });
        return userPics;
      } else {
        const userPic = {} as RegUserPics;
        userPic.Name =
          images.file != null && images.file !== 'undefined'
            ? images.file.name
            : '';
        userPic.PICRetrieveID = images.ImageID;
        userPic.IsProfilePic = images.IsProfilePic;
        userPics.push(userPic);
        return userPics;
      }
    }
  }

  updateUserPicsUrl(users: any): any {
    if (
      users !== null &&
      Object.prototype.toString.call(users) === '[object Array]' &&
      users.length > 0
    ) {
      users.forEach((user: any) => {
        if (
          user != null &&
          user.RelatedUserPics != null &&
          user.RelatedUserPics.length > 0
        ) {
          user.RelatedUserPics.forEach((userPic: any) => {
            userPic.Url =
            environment.apiUrl + '/Image/Get/' + userPic.PICRetrieveID;
          });
        }
      });
    } else if (
      users !== null &&
      typeof users !== 'undefined' &&
      users.RelatedUserPics != null &&
      users.RelatedUserPics.length > 0
    ) {
      users.RelatedUserPics.forEach((userPic: any) => {
        userPic.Url =
        environment.apiUrl + '/Image/Get/' + userPic.PICRetrieveID;
      });
    }
    return users;
  }

  prepareCurrentUserInfo(userInfo: RegUser) {
    if (!!userInfo) {
      this.currentUserInfo = {} as RegUser;
      this.currentUserInfo.AadharCard = userInfo.AadharCard;
      this.currentUserInfo.AadharRetriveId = userInfo.AadharRetriveId;
      this.currentUserInfo.Age = userInfo.Age;
      this.currentUserInfo.DateOfBirth = userInfo.DateOfBirth;
      this.currentUserInfo.Email = userInfo.Email;
      this.currentUserInfo.Gender = userInfo.Gender;
      this.currentUserInfo.IsActive = userInfo.IsActive;
      this.currentUserInfo.MobileNumber = userInfo.MobileNumber;
      this.currentUserInfo.Name = userInfo.Name;
      this.currentUserInfo.ProfileFor = userInfo.ProfileFor;
      this.currentUserInfo.ProfileID = userInfo.ProfileID;
      this.currentUserInfo.RelatedUserPics = userInfo.RelatedUserPics;
      this.currentUserInfo.SNo = userInfo.SNo;
      this.currentUserInfo.SubScribedPkg = userInfo.SubScribedPkg;
      this.currentUserInfo.UserStatus = userInfo.UserStatus;
      this.currentUserInfo.IsFromQuickRegistration =
        userInfo.IsFromQuickRegistration;
      this.currentUserInfo.IsEmailVerified = userInfo.IsEmailVerified;
      this.currentUserInfo.IsMobileVerified = userInfo.IsMobileVerified;
      this.currentUserInfo.ValidUpto = userInfo.ValidUpto;
      this.currentUserInfo.ProfilePercentage = userInfo.ProfilePercentage;
    }
    return this.currentUserInfo;
  }

  getUTCDate(date: any) {
    if (
      typeof date !== 'undefined' &&
      date != null &&
      !isNaN(Date.parse(date))
    ) {
      const dateFormat = new Date(date);
      return new Date(
        dateFormat.getTime() - dateFormat.getTimezoneOffset() * 60000
      ).toISOString();
    }
    return date;
  }

  getDefaultDateFormat(date: any) {
    if (typeof date !== 'undefined' && date != null && date.length > 0) {
      return this.datepipe.transform(date, 'dd/MM/yyyy');
    }
    return date;
  }

  validateCalendarByGender(gender: string) {
    const dobObj: any = { minDate: Date, maxDate: Date };
    if (typeof gender !== 'undefined') {
      const now = new Date();
      dobObj.minDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        0,
        0,
        0,
        0
      );
      dobObj.maxDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        0,
        0,
        0,
        0
      );
      dobObj.minDate.setFullYear(dobObj.minDate.getFullYear() - 70);
      if (gender === 'Female') {
        dobObj.maxDate.setFullYear(dobObj.maxDate.getFullYear() - 18);
        return dobObj;
      } else if (gender === 'Male') {
        dobObj.maxDate.setFullYear(dobObj.maxDate.getFullYear() - 21);
        return dobObj;
      }
    } else {
      return dobObj;
    }
  }

  prepareProfileCard(regUser: RegUser, isLoggedUser?: boolean): RegUser {
    let user = {} as RegUser;
    user = Object.assign(regUser, {
      IsFeaturedProfile: false,
      IsProfileHighliter: false,
      IsDiamondTag: false,
      IsPlatinumTag: false,
      IsPlatinumProfile: false,
      IsPremiumTag: false,
      IsFromQuickRegistration: regUser.IsFromQuickRegistration
    });

    if (!user.IsRequestViewEnabled || (isLoggedUser != null && isLoggedUser)) {
      if (!!user.RelatedUserPics?.length) {
        user.RelatedUserPics?.forEach((userPic) => {
          userPic.Url =
          environment.apiUrl + '/Image/Get/' + userPic.PICRetrieveID;
          if (userPic.IsProfilePic === true) {
            user.ProfileImage = userPic.Url;
          }
        });
        if (!user.RelatedUserPics?.some(c => c.IsProfilePic === true)) {
          user.ProfileImage = !!user?.RelatedUserPics?.length ? user.RelatedUserPics[0].Url : '';
        }
      } else {
        switch (user.Gender) {
          case 'Female':
            user.ProfileImage = 'assets/images/women-ic.png';
            break;
          case 'Male':
            user.ProfileImage = 'assets/images/men-ic.png';
            break;
          default:
            user.ProfileImage = 'assets/images/men-ic.png';
            break;
        }
      }
    } else {
      user.ProfileImage = 'assets/webp/shield.webp';
    }
    if (!!user.SubScribedPkg) {
      if (!!user.SubScribedPkg?.LstBadges?.length) {
        user?.SubScribedPkg?.LstBadges?.forEach((badgeItem: any) => {
          if (!!badgeItem?.Badge && !!badgeItem?.Badge?.BadgeName) {
            switch (badgeItem.Badge.BadgeName) {
              case 'Featured Profile': user.IsFeaturedProfile = true; break;
              case 'Profile Highlighter': user.IsProfileHighliter = true; break;
              case 'Diamond Tag': user.IsDiamondTag = true; break;
              case 'Platinum Tag': user.IsPlatinumTag = true; break;
              case 'Platinum Profile': user.IsPlatinumProfile = true; break;
              case 'Premium Tag': user.IsPremiumTag = true; break;
              default: break;
            }
          }
        });
        return user;
      } else {
        return user;
      }
    } else {
      return user;
    }
  }

  prepareProfileCardByPackage(user: RegUser): RegUser {
    user.IsFeaturedProfile = false;
    user.IsProfileHighliter = false;
    user.IsDiamondTag = false;
    user.IsPlatinumTag = false;
    user.IsPlatinumProfile = false;
    user.IsPremiumTag = false;
    if (!!user.SubScribedPkg) {
      if (!!user.SubScribedPkg?.LstBadges?.length) {
        user.SubScribedPkg?.LstBadges?.forEach((badgeItem: any) => {
          if (!!badgeItem?.Badge && !!badgeItem?.Badge?.BadgeName) {
            switch (badgeItem.Badge.BadgeName) {
              case 'Featured Profile': user.IsFeaturedProfile = true; break;
              case 'Profile Highlighter': user.IsProfileHighliter = true; break;
              case 'Diamond Tag': user.IsDiamondTag = true; break;
              case 'Platinum Tag': user.IsPlatinumTag = true; break;
              case 'Platinum Profile': user.IsPlatinumProfile = true; break;
              case 'Premium Tag': user.IsPremiumTag = true; break;
              default: break;
            }
          }
        });
      }
    }
    return user;
  }
}
