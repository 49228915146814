import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Country, State, City } from '../interfaces';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { HttpBaseService } from '../core/services/http-base.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends HttpBaseService {

  protected endpoint: string = '';

  constructor(
    protected http: HttpClient,
    protected httpBackendInterceptor: HttpClient,
    protected httpBackend: HttpBackend) {
    super();
  }

  getAllCountries(): Observable<Country[]> {
    this.endpoint = './assets/json/countries.json';
    return super.get<Country[]>()
  }
  
  getAllStates(): Observable<State[]> {
    this.endpoint = './assets/json/states.json';
    return super.get<State[]>()
  }
  
  getAllCities(): Observable<City[]> {
    this.endpoint = './assets/json/cities.json';
    return super.get<City[]>()
  }
  
}
