import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class StorageClientService {

  constructor(
    private storage: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: Object
    ) { }
  
  async set(storageKey: string, value: any) {
    if (isPlatformBrowser(this.platformId)) {
      const encryptedValue = btoa(encodeURI(JSON.stringify(value)));
      return await this.storage.store(storageKey, encryptedValue);
    }
  }

  async get(storageKey: string): Promise<any> {
    if (isPlatformBrowser(this.platformId)) {
      return new Promise(resolve => {
        const value = this.storage.retrieve(storageKey);
        !value ? resolve('') : resolve(JSON.parse(decodeURI(atob(value))))
      })
    }
  }

  async remove(storageKey: string) {
    if (isPlatformBrowser(this.platformId)) {
      await this.storage.clear(storageKey);
    }
  }

  async clear() {
    if (isPlatformBrowser(this.platformId)) {
      await this.storage.clear();
    }
  }

}
