import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[imgFallBack]'
})
export class ImgFallBackDirective {

  @Input() imgFallBack!: string;

  constructor(private eRef: ElementRef) { }

  @HostListener('error')
  loadFallbackOnError() {
    console.log("error")
    const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
    //element['ngSrc'] = "";
    // element.src = this.imgFallBack == 'Female' ? DEFAULT_USER_ICONS.MALE_ICON : DEFAULT_USER_ICONS.FEMALE_ICON || DEFAULT_USER_ICONS.BROKEN_IMAGE_ICON;
  }

}
