import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { AuthState } from '../interfaces';

import * as AuthSelector from '../state/auth/auth.reducer';
import * as AuthActions from '../state/auth/auth.actions';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authStore: Store<AuthState>, private injector: Injector) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            
            let user$ = this.authStore.select(AuthSelector.selectUser);
            if (!!err?.status && [401, 403].includes(err.status) && user$) {
                // auto logout if 401 or 403 response returned from api
                // let loader = this.injector.get(LoaderInterceptor);
                // loader?.hideLoader();
                
                // this.dismissSpinner();
                this.authStore.dispatch(AuthActions.logOut());
                
            } else if ([400].includes(err.status)) {
                // this.dismissSpinner();
            }

            return throwError(err);
        }))
    }
}
