import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { NgModule } from '@angular/core';
import { PrimeNGModule } from './modules';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';


import { ErrorInterceptor } from '../helpers/error-interceptor';
import { LoaderInterceptor } from '../helpers/loader-interceptor';
import { RequestInterceptor } from '../helpers/request-interceptor';
import { BreadCrumbComponent, ImageViewerComponent } from './components';
import { ImageCropperComponent } from './modules/image-cropper/component/image-cropper.component';
import { LoginOperationsService } from '../services/login-operations.service';
import { CommonOperations } from '../helpers/common-operations';
import { UserService } from '../services/user.service';
import { UserSearchService } from '../services/user-search.service';
import { PackageService } from '../services/package.service';
import { ChatRetrieverService } from '../services/chat-retriever.service';

import { register } from 'swiper/element/bundle';
import { ImageDirective, ImgFallBackDirective, ProfileImageDirective } from './directives';
import { LocationService } from '../helpers/location-service';
import { StorageClientService } from './services/storage-client.service';
import { UserStatusPipe } from './pipes/user-status.pipe';

register();

const SHARED_COMPONENTS: any = [
    BreadCrumbComponent,
    ImageCropperComponent,
    ImageViewerComponent
  ];
  
const SHARED_DIRECTIVES = [
  ProfileImageDirective,
  ImgFallBackDirective,
  ImageDirective
]
const SHARED_MODULES: any[] = [
    CommonModule,
    FormsModule,
    PrimeNGModule,
    NgxSpinnerModule,
    NgOptimizedImage,
    HttpClientModule,
    ReactiveFormsModule,
    QuillModule.forRoot({
      suppressGlobalRegisterWarning: true
    }),
];

const SHARED_PIPES: any[] = [
  UserStatusPipe
]
  
@NgModule({
    declarations: [SHARED_COMPONENTS, SHARED_DIRECTIVES, SHARED_PIPES, ImageViewerComponent],
    imports: [
        SHARED_MODULES
    ],
    exports: [
      SHARED_PIPES,
      SHARED_DIRECTIVES,
      SHARED_COMPONENTS,
      SHARED_MODULES
    ],
    providers: [
      { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      LoginOperationsService,
      LocationService,
      CommonOperations,
      DatePipe,
      UserService,
      UserSearchService,
      PackageService,
      ChatRetrieverService,
      StorageClientService
    ]
  })
  export class CoreModule { }
  