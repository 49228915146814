import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

export abstract class HttpBaseService {

  protected abstract endpoint: string;
  protected abstract http: HttpClient;
  protected abstract httpBackend: HttpBackend;
  // protected abstract httpBackendInterceptor: HttpClient;

  public requestOptions: {
    headers?: HttpHeaders | {[header: string]: string | string[]},
    observe?: 'body',
    params?: HttpParams|{[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>},
    reportProgress?: boolean,
    responseType?: any | 'arraybuffer'|'blob'|'json'|'text',
    withCredentials?: boolean,
  }

  constructor() {
    this.requestOptions = { withCredentials: false };  
  }

  get<TResult>(action: string = '', params?: HttpParams) {
    return this.http.get<TResult>(this.endpoint + action, 
      params ? { ...this.requestOptions, params } : this.requestOptions);
  }

  getSilent<TResult>(action: string = '', params?: HttpParams) {
    return this.http.get<TResult>(this.endpoint + action, 
      params ? { ...this.requestOptions, params } : this.requestOptions);
  }

  post<TBody, TResult>(action: string = '', body: TBody | any = null, params?: HttpParams) {
    return this.http.post<TResult>(this.endpoint + action, body, 
      params ? { ...this.requestOptions, params } : this.requestOptions);
  }

  postSilent<TBody, TResult>(action: string = '', body: TBody | any = null, params?: HttpParams) {
    return this.http.post<TResult>(this.endpoint + action, body, 
      params ? { ...this.requestOptions, params } : this.requestOptions);
  }

  patch<TBody, TResult>(action: string = '', body: TBody | any = null, params?: HttpParams) {
    return this.http.patch<TResult>(this.endpoint + action, body, 
      params ? { ...this.requestOptions, params } : this.requestOptions);
  }

  put<TBody, TResult>(action: string = '', body: TBody | any = null, params?: HttpParams) {
    return this.http.put<TResult>(this.endpoint + action, body, 
      params ? { ...this.requestOptions, params } : this.requestOptions);
  }

  delete<TBody, TResult>(action: string = '', params?: HttpParams) {
    return this.http.delete<TResult>(this.endpoint + action, 
      params ? { ...this.requestOptions, params } : this.requestOptions);
  }
  
}
