import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { RegUser } from '../../interfaces';
import { LoginOperationsService } from '../../services/login-operations.service';
import { environment } from '../../../environments/environment';
import { DEFAULT_USER_ICONS, GENDER } from '../utilities/constants';


class ProfileImageContext {
  public profileImage!: string | ArrayBuffer | SafeUrl;
}

@Directive({
  selector: '[profileImage]'
})
export class ProfileImageDirective implements OnChanges {

  @Input('profileImage')
  user: RegUser = {} as RegUser;

  private context = new ProfileImageContext;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private loginOperationsService: LoginOperationsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.user?.ProfileID) {
      let profilePicItem = this.user.RelatedUserPics?.filter(item => item.IsProfilePic);
      let imageId = !!profilePicItem?.length ? profilePicItem[0].PICRetrieveID: null;
      
      if (!!imageId) {
        this.loginOperationsService.getImage({ imageId: imageId, gender: this.user.Gender }).subscribe({
          next: (response: any) => {
            if (typeof response == 'object') {
              // let TYPED_ARRAY = new Uint8Array(response['__zone_symbol__value']);
              // const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
              //   return data + String.fromCharCode(byte);
              //   }, '');
              // let base64String = btoa(STRING_CHAR);
              // this.prepareReturnValue('data:image/png;base64,' + base64String);
              if (profilePicItem?.length){
                this.prepareReturnValue(`${environment.imagePath}/${profilePicItem[0].PICRetrieveID}`);
              }
            } else {
              this.prepareReturnValue((this.user.Gender == GENDER.FEMALE) ? DEFAULT_USER_ICONS.FEMALE_ICON : DEFAULT_USER_ICONS.MALE_ICON);
            }
          },
          error: (err: any) => {
            this.prepareReturnValue((this.user.Gender == GENDER.FEMALE) ? DEFAULT_USER_ICONS.FEMALE_ICON : DEFAULT_USER_ICONS.MALE_ICON);
          }
        });
      } else {
        this.prepareReturnValue((this.user.Gender == GENDER.FEMALE ? DEFAULT_USER_ICONS.FEMALE_ICON : DEFAULT_USER_ICONS.MALE_ICON));
      }
    }
  }

  prepareReturnValue(profileImage: string | ArrayBuffer | SafeUrl): void {
    this.context.profileImage = profileImage;
    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
  }
}
