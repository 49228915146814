export enum DiscountType {
  Discount,
  Price,
  Cashback
}

export namespace DiscountType {
  export function values() {
    return Object.keys(DiscountType).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}
