import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest, map, mergeMap, take } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { AuthState } from '../interfaces';

import * as AuthSelector from  '../state/auth/auth.reducer';

@Injectable({ providedIn: 'root' })
export class RequestInterceptor implements HttpInterceptor {
    
    constructor(private authStore: Store<AuthState>) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        const accessTokenStorage = this.authStore.pipe(select(AuthSelector.acessToken));
        const refreshTokenStorage = this.authStore.pipe(select(AuthSelector.refreshToken));

        return combineLatest([accessTokenStorage, refreshTokenStorage]).pipe(
            take(1),
            map(([src1, src2]) => ({ access_token: src1, refresh_token: src2 })),
            mergeMap((token: any) => {            
                let headerData = req.headers;
                // headerData = headerData.append('Content-Type', 'application/json');
                headerData = headerData.set('Token', token.access_token || '');

                if (req.url.indexOf('/RefreshToken') >= 0) {
                    headerData = headerData.set('RefreshToken', token.refresh_token || '');
                }
        
                const authReq = req.clone({
                    headers: headerData
                });
                
                return next.handle(authReq)
            })
        )
    }
}
