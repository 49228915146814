import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { RegUser } from '../../../interfaces';
import { isPlatformBrowser } from '@angular/common';
import Swiper from 'swiper';
import { SwiperContainer } from 'swiper/element';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrl: './image-viewer.component.scss'
})
export class ImageViewerComponent implements OnInit {
  @Input() pics: any[] = [];
  @Input() user: RegUser = {} as RegUser;
  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
      
    if (isPlatformBrowser(this.platformId)) {
      // Client only code.  
      var profileImageSwiper = new Swiper('.profileImageSwiper', {
        slidesPerView: 'auto',
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 'auto',
          },
        },
        spaceBetween: 30,
        centeredSlides: true,
        centerInsufficientSlides: true,
        centeredSlidesBounds: true,
        scrollbar: true,
        keyboard: true,
        mousewheel: true,
        slidesPerGroup: 1,
        pagination: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    }
  }

}
