import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { LoginOperationsService } from '../../services/login-operations.service';
import { DEFAULT_USER_ICONS } from '../utilities/constants';
import { environment } from '../../../environments/environment';

class ImageContext {
  public smImage!: string | ArrayBuffer | SafeUrl;
}

@Directive({
  selector: '[smImage]'
})
export class ImageDirective implements OnChanges {

  @Input('smImage')
  imageId!: string;

  private context = new ImageContext;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private loginOperationsService: LoginOperationsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.imageId) {
      this.loginOperationsService.getImage({ imageId: this.imageId }).subscribe({
        next: (response: any) => {
          if (typeof response == 'object') {
            // let TYPED_ARRAY = new Uint8Array(response['__zone_symbol__value']);
            // const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
            //   return data + String.fromCharCode(byte);
            //   }, '');
            // let base64String = btoa(STRING_CHAR);
            // this.prepareReturnValue('data:image/png;base64,' + base64String);
            this.prepareReturnValue(`${environment.imagePath}/${this.imageId}`);

          } else {
            this.prepareReturnValue(DEFAULT_USER_ICONS.BROKEN_IMAGE_ICON);
          }
        },
        error: (err: any) => this.prepareReturnValue(DEFAULT_USER_ICONS.BROKEN_IMAGE_ICON)
      });
    }
  }

  prepareReturnValue(profileImage: string | ArrayBuffer | SafeUrl): void {
    this.context.smImage = profileImage;
    this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
  }
}
