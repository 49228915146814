import { Injectable, inject } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {

  durationInSeconds = 5000;
  private messageService = inject(MessageService) ;

  constructor(
  ) { }

  successToastr(msg: string) {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: msg });
  }
  
  errorToastr(msg: string) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: msg });
  }
  
  warningToastr(msg: string) {
    this.messageService.add({ severity: 'warn', summary: 'Warning', detail: msg });
  }

  infoToastr(msg: string) {
    this.messageService.add({ severity: 'info', summary: 'Info', detail: msg });
  }

  defaultToastr(summary: string, msg: string) {
    this.messageService.add({ severity: 'secondary', summary: summary, detail: msg });
  }
  
  darkToastr(summary: string, msg: string) {
    this.messageService.add({ severity: 'contrast', summary: summary, detail: msg });
  }
  
}
