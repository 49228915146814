import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { RegPackages } from '../interfaces';
import { CommonOperations } from '../helpers/common-operations';
import { map, mergeMap, shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpBaseService } from '../core/services/http-base.service';

const DEFAULT_ARRAY: any[] = [];

@Injectable()
export class PackageService extends HttpBaseService implements OnDestroy {
  protected endpoint: string = '';
  packages$ = new BehaviorSubject<RegPackages[]>(DEFAULT_ARRAY);

  constructor(
    protected http: HttpClient,
    protected httpBackendInterceptor: HttpClient,
    protected httpBackend: HttpBackend,
    private cOps: CommonOperations
  ) { 
    super();
    this.httpBackendInterceptor = new HttpClient(this.httpBackend); 
  }

  getPackages() {
    this.endpoint = `${environment.apiUrl}/Package/GetPackages`;
    super.get<RegPackages[]>().pipe(
      map(packages => {
        
        if (!!packages?.length) {
          packages.forEach((value, index) => {
            value.StartAt = 0;
            value.lstPackageRates?.forEach((rateValue, rateIndex) => {
              if (value.StartAt) {
                value.StartAt = rateIndex === 0 ? rateValue.Amount : rateValue.Amount < value.StartAt ? rateValue.Amount : value.StartAt;
              }
            });
          });
        }

        this.packages$.next(packages);
      }),
      shareReplay()
    ).subscribe();
  }
  
  getActivePackages(): Observable<RegPackages[]> {
    this.endpoint = `${environment.apiUrl}/Package/GetPackages`;
    return super.get<RegPackages[]>()
  }

  getAllPackages(): Observable<RegPackages[]> {
    this.endpoint = `${environment.apiUrl}/Package/GetAllPackages`;
    return super.get<RegPackages[]>().pipe(
      mergeMap(async response => {
        if (response) {
          response?.forEach(item => {
            if (item.PICRetreiverID) {
              item.PackageImage = this.cOps.getImage(item.PICRetreiverID);
            }
          })
        }
        return response;
      })
    );
  }
  
  insert(regPackageDto: RegPackages): Observable<boolean> {
    this.endpoint = `${environment.apiUrl}/Package/Insert`;
    return super.post<RegPackages, boolean>('', regPackageDto)
  }

  edit(regPackageDto: RegPackages): Observable<boolean> {
    this.endpoint = `${environment.apiUrl}/Package/Edit`;
    return super.post<RegPackages, boolean>('', regPackageDto)
  }

  deletePackage(packageSNo: number): Observable<boolean> {
    this.endpoint = `${environment.apiUrl}/Package/Delete/${packageSNo}`;
    return super.delete<number, boolean>()
  }

  ngOnDestroy(): void {
      this.packages$?.unsubscribe();
      this.packages$ = new BehaviorSubject<RegPackages[]>(DEFAULT_ARRAY);
  }
}
