import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ImageInfo } from '../interfaces';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  authState = new BehaviorSubject(false);
  constructor(
    private httpService: HttpClient
  ) { }

  uploadFile(formData: FormData) {
    // const formData = new FormData();
    const promise = new Promise((resolve, reject) => {
      this.httpService
          .post(`${environment.apiUrl}/FileUpload/UploadFile`, formData)
          .subscribe(result => {
            resolve(result);
          },
          error => {
            reject(error);
          });
        });
    return promise;
  }

  dataURItoBlob(dataURI: any) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/png',
    });
  }

  // ImageCroppedEvent
  uploadImage(image: any): Observable<ImageInfo> {
    const formData = new FormData();
    const blob = this.dataURItoBlob(image.base64);
    const imageInfo = {} as ImageInfo;
    imageInfo.DataUrl = image.base64;
    imageInfo.Name = image.Name;
    imageInfo.IsProfilePic = image.IsProfilePic;
    imageInfo.Size = blob.size;
    imageInfo.Type = blob.type;
    imageInfo.Format = blob.type;
    imageInfo.IsActive = image.IsActive;
    imageInfo.SNo = image.SNo;
    imageInfo.Url = '';
    imageInfo.ImageID = '';
    const imageData = JSON.stringify(imageInfo);
    formData.append('image', imageData);

    const promise = new Observable((subscriber) => {
      this.httpService.post(`${environment.apiUrl}/FileUpload/UploadFile`, formData)
        .subscribe((res: any) => {
          imageInfo.PICRetrieveID = res.ImageID;
          subscriber.next(imageInfo);
          subscriber.complete();
        })
      })

    return promise as Observable<ImageInfo>;
}


  deleteFile(imageId: string) {
    const promise = new Promise((resolve, reject) => {
      this.httpService
      .delete(`${environment.apiUrl}/FileUpload/DeleteFile?imageId=${imageId}`)
      .subscribe(
        data => {
          const result = data as boolean;
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
    return promise;
  }


}
