
export enum RazorPaymentStatus {
  None,
  //The default status of payment when its request is first recieved by Razorpay. No processing has been done at this status.
  Created,
  //Set after successful authorization of payment. The money has been deducted from customer account but not yet transferred from his bank.
  Authorized,
  //Set after successful capture of payment. After this, you will receive the money within T+3 day of the capture.
  Captured,
  //Set after a captured payment has been fully refunded. It's not set for partially refunded payments.
  Refunded,
  //Set if the payment failed due to some error. Check the error field for actual cause.
  Failed
}

export namespace RazorPaymentStatus {
  export function values() {
    return Object.keys(RazorPaymentStatus).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}
