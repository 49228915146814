
export const TOKEN_CONFIGURATION = {
    AccessToken: '',
    RefreshToken: '',
    RefreshInitiator: false
};

export const TOASTR_SUMMARY = {
  ERROR: "Error", 
  INFO: "Info",
  SUCCESS: "Success",
  WARN: "Warn"
}

export enum GENDER {
  FEMALE = "Female",
  MALE = "Male"
}

export enum STATE_KEYS {
  AUTH = 'auth',
  REGISTER = 'register',
  LOCATION =  'location',
  FILTERS_REFINERS =  'filters_refiners',
  MATCHES =  'matches',
}

export namespace LOCAL_STORAGE_KEY {
  export const USER = "SM_USER";
  export const USER_ROLE = "SM_USER_ROLE";
  export const ACCESS_TOKEN = "SM_ACCESS_TOKEN";
  export const REFRESH_TOKEN = "SM_REFRESH_TOKEN";
  export const EMAIL = "SM_EMAIL";
  export const PASSWORD = "SM_PASSWORD";
}

export enum DEFAULT_USER_ICONS {
  MALE_ICON = "./assets/images/men-ic.png",
  FEMALE_ICON = "./assets/images/women-ic.png",
  BROKEN_IMAGE_ICON = "./assets/icon/broken-image.png",
  SHIELD_ICON = "./assets/webp/shield.webp"
}

export const TOASTR_SEVERITY = {
  ERROR: "error", 
  INFO: "info",  
  SUCCESS: "success",
  WARN: "warn"
}

export const TOASTR_MESSAGE = {
  ACTIVATED_SUCCESSFULLY: "Activated Successfully",
  ARE_YOU_SURE_DELETE: "Are you sure you want to delete ?",
  ARE_YOU_SURE_ACIVATE: "Are you sure you want activate ?",
  ARE_YOU_SURE_DEACTIVATE: "Are you sure you want de-activate ?",
  CONFIRM: "Confirm",
  DEACTIVATED_SUCCESSFULLY: "Deactivated Successfully",
  DELETED_SUCCESSFULLY: "Deleted Successflly",
  ERROR_OCCURRED: "Error Occurred",
  INVALID_FORM: "Form is invalid",
  REQUIRED_FIELDS_ARE_EMPTY: 'Required fields are empty!',
  SAVED_SUCCESSFULLY: "Saved Successfully!!!",
  UPDATED_SUCCESSFULLY: "Updated Successfully",
}

export const DIALOG_RESPONSE = {
  RELOAD_GRID: 'Reload Grid'
}
  