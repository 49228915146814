import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { CommonOperations } from '../helpers/common-operations';
import { HttpBaseService } from '../core/services/http-base.service';
import { AuthState, RegUser, RegUserInformation, TransactionDetails, UserInfo } from '../interfaces';
import { Store } from '@ngrx/store';

import * as AuthSelector from '../state/auth/auth.reducer';

@Injectable({
  providedIn: 'root'
})
export class UserService extends HttpBaseService implements OnDestroy {

  protected endpoint: string = '';
  private userInfo: UserInfo = {} as UserInfo;

  constructor(
    protected http: HttpClient,
    protected httpBackendInterceptor: HttpClient,
    protected httpBackend: HttpBackend,
    private authStore: Store<AuthState>,
    private cOps: CommonOperations
  ) {
    super();
    this.httpBackendInterceptor = new HttpClient(this.httpBackend); 
    this.authStore.select(AuthSelector.userInfoSelector).subscribe((userInfo) => {
      if (userInfo) {
        this.userInfo = userInfo
      }
    })
  }

  insertUser(regUser: RegUser): Observable<boolean> {
    this.endpoint = `${environment.apiUrl}/User/Insert`;
    return super.post<RegUser, boolean>('', regUser);
  }

  getAllBasicInformation(): Observable<RegUser[]> {
    this.endpoint = `${environment.apiUrl}/User/GetAllBasicInformation`;
  
    return super.get<RegUserInformation>().pipe(
      mergeMap(async response => {
        let users = this.cOps.updateUserPicsUrl(response)
        users.forEach((user: RegUser) => {
          user.ProfileImage = this.cOps.getProfileImage(user);
          user = this.cOps.prepareProfileCardByPackage(user);
        }); 
        return users;
    }));
  }
  
  updateProfileComplete(profileID: string): Observable<boolean> {
    this.endpoint = `${environment.apiUrl}/User/UpdateProfileComplete/${profileID}`;
    let headerData = new HttpHeaders();
    headerData = headerData.set('HeaderInfo', JSON.stringify(this.userInfo));
    this.requestOptions.headers = headerData;
    return super.post<string, boolean>()
  }

  // only for admin
  getUserDetailsAdmin(ProfileID: string): Observable<RegUser> {
    this.endpoint = `${environment.apiUrl}/User/GetUserDetailsAdmin/${ProfileID}`;
    return super.get<RegUser>().pipe(
      map(response => this.cOps.prepareProfileCard(response))
    );
  }
  
  getUserProfile(profileSNo: string): Observable<RegUser> {
    this.endpoint = `${environment.apiUrl}/User/GetUserProfile/${profileSNo}`;
    return super.get<RegUser>().pipe(
      map(response => this.cOps.prepareProfileCard(response))
    );
  }
  getTransactionsByUser(userSNo: number): Observable<TransactionDetails[]> {
    this.endpoint = `${environment.apiUrl}/User/GetAllTransactionsByUser?userSNo=${userSNo}`;
    return super.get<TransactionDetails[]>();
  }


  getAllUsersBasicInformationAdmin(): Observable<RegUser[]> {
    this.endpoint = `${environment.apiUrl}/User/GetAllUsersBasicInformationAdmin`;
  
    return super.get<RegUserInformation>().pipe(
      mergeMap(async response => this.cOps.updateUserPicsUrl(response)));
  }

  ngOnDestroy(): void {
  }
  
}
