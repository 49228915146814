import { Pipe, PipeTransform } from '@angular/core';
import { RegUserStatus } from '../../enums';

@Pipe({
  name: 'userStatus'
})
export class UserStatusPipe implements PipeTransform {

  transform(value: number, textCase: 'lowercase' | 'uppercase' | '' = ''): string {
    if (value < 0) return "NA";

    let status = this.getStatus(value);

    if (!textCase) return status;

    switch (textCase) {
      case 'lowercase':
        return status.toLowerCase()
      case 'uppercase':
        return status.toUpperCase()
      default:
        return status;
    }

    
  }

  private getStatus(status: number): string {
    switch (status) {
      case RegUserStatus.Active:
        return "Active";
      case RegUserStatus.Inactive:
        return "In-Active";
      case RegUserStatus.UnderVerification:
        return "Verification";
      default:
        return "NA"
    }
  }
}
